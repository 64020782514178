.skills-card {
  border-radius: 20px;
}

@media (min-width: 1200px) {
  .skills-card {
    width: 48% !important;
    padding: 50px;
  }
}

@media (max-width: 1199px) {
  .skills-card {
    width: 98% !important;
    padding: 21px;
  }
}
