@media (min-width: 1200px) {
  .about-me {
    padding: 27.5vh 12vw;
  }
}

@media (max-width: 1199px) {
  .about-me {
    padding: 7vh 6vw;
  }
}

.about-me-img {
  height: 180px;
  width: 180px;
  border-radius: 90px;
}

@media (min-width: 1200px) {
  .about-me-text-container {
    max-width: 53vw;
  }
}

.rainbow-title {
  background: linear-gradient(
    135deg,
    #ff0000,
    #ff8000,
    #ffff00,
    #00ff00,
    #0000ff,
    #4b0082,
    #8f00ff,
    #ffffff
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent !important;
  background-size: 400% 400%;
  animation: rainbowBackground 20s ease infinite;
}

@keyframes rainbowBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
