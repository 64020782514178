.image-placeholder {
  border: 1px solid black;
  border-radius: 0.75rem;
  z-index: 1;
  position: relative; /* Establish a positioning context */
}

.image-placeholder:hover {
  opacity: 0.5;
}

.see-more-text {
  position: absolute;
  top: 0;
  opacity: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.see-more-text:hover {
  opacity: 1;
}
