@media (min-width: 1200px) {
  .experience-card {
    width: 30vw;
    border-radius: 10px;
    padding: 40px;
  }

  .experience-box {
    max-width: 44vw;
    word-wrap: break-word;
    padding: 2vw 4vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
  }

  .org-img {
    width: 20vw;
  }
}

@media (max-width: 1199px) {
  .experience-card {
    width: 100%;
    transform: none !important;
    border-radius: 10px;
    padding: 40px;
  }

  .experience-box {
    max-width: 100%;
    word-wrap: break-word;
    padding: 2vw 4vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
  }

  .org-img {
    width: 50%;
  }
}

.experience-title {
  font-size: x-large;
  font-weight: bold;
}

.experience-subtitle,
.experience-description {
  font-size: 20px;
  font-weight: 500;
  white-space: pre-wrap;
  ul {
    padding-left: 10px;
    list-style-type: square;
  }
}

.experience-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
